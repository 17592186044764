import camelCase from 'lodash/camelCase'

import { API_DATE_REGEXP } from './constants'
import { isObject } from './isObject'

export const camelCasifyProperties = (entity: unknown): any => {
  if (Array.isArray(entity)) {
    return entity.map(camelCasifyProperties)
  }

  if (isObject(entity)) {
    return Object.keys(entity).reduce(
      (acc, key) => ({
        ...acc,
        [API_DATE_REGEXP.test(key) ? key : camelCase(key)]: camelCasifyProperties(entity[key]),
      }),
      {}
    )
  }

  return entity
}
